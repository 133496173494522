<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="80px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="分类名称" prop="name">
        <el-input
          v-model="dataForm.name"
          :disabled="disabled"
          placeholder="分类名称"
        />
      </el-form-item>
      <el-form-item label="分类名称" prop="level">
        <el-radio-group v-model="dataForm.level" :disabled="disabled">
          <el-radio :label="1"> 一级 </el-radio>
          <el-radio :label="2"> 二级 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="dataForm.level !== 1" label="父节点" prop="parentId">
        <el-select
          v-model="dataForm.parentId"
          :disabled="disabled"
          clearable
          filterable
          placeholder="请选择"
          class="width185"
        >
          <el-option
            v-for="parent in parents"
            :key="parent.id"
            :label="parent.name"
            :value="parent.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否显示" prop="isShow">
        <el-radio-group v-model="dataForm.isShow" :disabled="disabled">
          <el-radio :label="1"> 是 </el-radio>
          <el-radio :label="0"> 否 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="dataForm.level === 1" label="顶部大图" prop="imgUrl">
        <el-img v-model="dataForm.imgUrl" :disabled="disabled" />
      </el-form-item>
      <el-form-item v-if="dataForm.level === 2" label="icon链接" prop="iconUrl">
        <el-img v-model="dataForm.iconUrl" :disabled="disabled" />
      </el-form-item>
      <el-form-item label="简介" prop="frontName">
        <el-input
          v-model="dataForm.frontName"
          :disabled="disabled"
          placeholder="简介"
        />
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input
          v-model="dataForm.sort"
          :disabled="disabled"
          placeholder="排序"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: '',
        name: '',
        level: 1,
        parentId: '',
        sort: 1,
        isShow: 1,
        imgUrl: '',
        iconUrl: '',
        frontName: '',
      },
      parents: [],
      dataRule: {
        name: [
          {
            required: true,
            message: '名称不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/mall/category/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = data.category;
            }
          });
        } else {
          this.dataForm = {
            id: '',
            name: '',
            level: 1,
            parentId: '',
            sort: 1,
            isShow: 1,
            imgUrl: '',
            iconUrl: '',
            frontName: '',
          };
        }
        this.$http({
          url: '/mall/category/queryAll',
          method: 'get',
          params: {
            parentId: 0,
          },
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.parents = data.list;
          }
        });
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/mall/category/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
